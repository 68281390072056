import { defineStore } from 'pinia';
import type { PaginationByPage } from '@nuxtjs/strapi/dist/runtime/types/v4';
import type {
  IGetListResult,
  IPromotion,
} from '@/types';

export const usePromotionsStore = defineStore('promotions', {
  state: (): IGetListResult<IPromotion> => {
    return {
      data: [],
      meta: {
        pagination: {
          page: 1,
          pageSize: 10,
          pageCount: 0,
          total: 0,
        },
      }
    };
  },
  getters: {
    mainPromotion (state): IPromotion | null {
      const mainPromotions = state.data.filter((item) => item.isMain);
      const promotion = mainPromotions[Math.floor(Math.random() * mainPromotions.length)];

      return promotion || null;
    },
  },
  actions: {
    async getAll(pagination: Partial<PaginationByPage> = {}) {
      const { find } = useStrapi();
      const response = await find<IPromotion>('promotions', {
        populate: 'deep',
        sort: [
          'createdAt:asc',
        ],
        pagination: {
          ...({
            page: 1,
            pageSize: 10,
            withCount: true,
          }),
          ...pagination,
        },
      });

      this.data = response.data as unknown as IPromotion[];
      this.meta = response.meta as unknown as IGetListResult<IPromotion>['meta'];
    }
  },
});
